import { useState } from "react"
import { CircularProgress, Stack, Typography, Chip } from "@mui/material"
import { Dialog, Paper, colors } from "@parallel-domain/pd-theme"

// import app components
import useCustomSession from "features/authentication/hooks/useSession"

type Props = {
  open: boolean
  onClose: () => void
}

const OrgSwitcher = (props: Props) => {
  const { open, onClose } = props

  const [loading, setLoading] = useState<null | number>(null)

  const { data: session, update } = useCustomSession()

  const handleSwitchOrganization = async (organization_id: number) => {
    try {
      setLoading(organization_id)
      await update({ organization_id })
    } catch (e) {
      // Nothing to do here
    } finally {
      setLoading(null)
      onClose()
    }
  }

  return (
    <Dialog open={open} title="Switch Organization" onClose={() => !loading && onClose()}>
      <Stack spacing={2}>
        {session?.user?.organizations &&
          session.user.organizations.map((o) => {
            const isActive = o.organization_id === session.user.activeOrganization.organization_id

            return (
              <Paper
                key={o.organization_id}
                backgroundDark={false}
                sx={{
                  ...(!isActive && {
                    border: 1,
                    borderColor: colors.border[100],
                    cursor: "pointer",
                    "&:hover": { borderColor: "primary.main" },
                  }),
                }}
                onClick={() => !isActive && handleSwitchOrganization(o.organization_id)}
              >
                <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: isActive ? colors.text[700] : colors.text[100],
                      }}
                    >
                      {o.name}{" "}
                      {isActive && session.user.organizations.length > 1 && (
                        <Chip label="Active" color="primary" size="small" />
                      )}
                    </Typography>
                  </Stack>

                  {loading === o.organization_id && <CircularProgress size={20} />}
                </Stack>
              </Paper>
            )
          })}
      </Stack>
    </Dialog>
  )
}

export default OrgSwitcher
