import React from "react"
import { MenuItem } from "@mui/material"
import { Select } from "@parallel-domain/pd-theme"
import { useRouter } from "next/router"
import { SxProps, Theme } from "@mui/material/styles"

// import app components
import useReleases from "../store/useReleases"
import * as DOCS_CONSTANTS from "features/docs/constants"
import useCustomSession from "features/authentication/hooks/useSession"

export type Props = {
  sx?: SxProps<Theme>
}

const ReleaseToggle = (props: Props) => {
  const { sx = {} } = props

  const { data: session } = useCustomSession()

  const router = useRouter()

  const activeRelease = useReleases((state) => state.activeRelease)
  const setActiveRelease = useReleases((state) => state.setActiveRelease)

  const handleSelectRelease = (name: string) => {
    setActiveRelease(name)

    if (router.asPath.startsWith(`/${DOCS_CONSTANTS.README_BASE_DIRECTORY}/`)) {
      // Navigate back to docs homepage because we can't be sure if page exists
      router.push(`/${DOCS_CONSTANTS.README_BASE_DIRECTORY}/${name}/${DOCS_CONSTANTS.README_ENTRY_PAGE}`)
    } else if (router.asPath.startsWith("/explore")) {
      // Replace version in pathname
      const newPathname = router.asPath.replace(activeRelease, name)
      router.push(newPathname)
    }
  }

  return (
    <>
      <Select
        value={activeRelease}
        sx={{
          ".MuiInputBase-root": { background: "transparent", border: "none" },
          ".MuiSelect-select": { fontSize: 12 },
          ...sx,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectRelease(e.target.value)}
      >
        {session.user.activeOrganization.releases.map((name) => {
          return (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}

export default ReleaseToggle
